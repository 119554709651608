/* eslint-disable @typescript-eslint/no-use-before-define */
import { ApplicationState } from "@redux/modules";
import { ValidAuthResults } from "@redux/modules/authValid";
import { AuthProviders, NumericAccessLevel } from "@types";
import isEqual from "lodash/isEqual";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useSelector } from "react-redux";

import { logoutAutomatically } from "../../auth/auth";

export interface PropTypes {
    timeout: number; // number of seconds after which to logout the user
    providers: AuthProviders[]; // the auth providers that should be logged out
}

declare global {
    interface Window {
        testLogout: (launch: boolean) => void;
        testTimer: () => void;
    }
}

export const LogoutWhenIdle = ({ timeout, providers }: PropTypes): JSX.Element => {
    const authSlice = useSelector(
        (state: ApplicationState) => state.authValid.data as unknown as ValidAuthResults,
        isEqual,
    ); //redux store using hooks
    const [idle, setIdle] = useState(false);
    const testEnv = process.env.GATSBY_ENVIRONMENT === "qa";

    useEffect(() => {
        //exposing timer globally
        window.testLogout = (launch: boolean) => {
            // eslint-disable-next-line no-console
            console.info(launch ? "Enabling AutoLogout" : "Disabling AutoLogout");
            launch ? start() : pause();
        };

        window.testTimer = () => {
            setInterval(() => {
                // eslint-disable-next-line no-console
                console.info(`Logout in: ${Math.trunc(getRemainingTime() / 1000)}`);
            }, 10000);
        };
    }, []);

    useEffect(() => {
        if (authSlice && idle) {
            const lvl3Providers = authSlice.results.reduce((acc, cur) => {
                if (cur.level === NumericAccessLevel.level3) {
                    acc.push(cur.provider);
                }

                return acc;
            }, []);

            const providersToLogout = lvl3Providers.filter((p) => providers.includes(p));

            if (providersToLogout.length) {
                // eslint-disable-next-line no-console
                console.info(`Logging out due to inactivity after ${timeout} seconds`, "providers:", providersToLogout);
                isLeader() ? logoutAutomatically(providersToLogout) : window.location.reload(); //if page is considered leader, trigger logout, other tabs simply refresh
            }
        }
    }, [idle]);

    const { isLeader, start, pause, getRemainingTime } = useIdleTimer({
        timeout: timeout * 1000,
        onIdle: () => {
            setIdle(true);
        },
        debounce: 500,
        startOnMount: !testEnv,
        startManually: testEnv,
        crossTab: {
            emitOnAllTabs: true,
            channelName: `idle-timer ${providers?.toString()}`,
        },
    });

    return <></>;
};

export default LogoutWhenIdle;
