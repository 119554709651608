import { State } from "@redux/create-api-module";
import { CMSProductProps, PageContext } from "@types";
import { MutableRefObject } from "react";
import { withReduxStateSync } from "redux-state-sync";

import { combineReducers } from "../../../../../node_modules/redux";
import analytics from "./analytics";
import askJanssen from "./askJanssen";
import askTheExperts, { AskTheExperts } from "./askTheExperts";
import askTheExpertsQuestion, { QuestionState } from "./askTheExpertsQuestion";
import askTheExpertsQuestions, { QuestionList } from "./askTheExpertsQuestions";
import authValid from "./authValid";
import automaticallyRegisterForEvent from "./automaticallyRegisterForEvent";
import certificates from "./certificates";
import chat from "./chat";
import codsId from "./codsId";
import course from "./course";
import courses from "./courses";
import currentCourses from "./currentCourses";
import drugInteractions from "./drugInteractions";
import drugSearch from "./drugSearch";
import eligibleEventNumber from "./eligibleEventNumber";
import eligibleEvents from "./eligibleEvents";
import event from "./event";
import eventRegistration from "./eventRegistration";
import eventsAttendance from "./eventsAttendance";
import header from "./header";
import materialCollection, { MaterialSetType } from "./materialCollection";
import msl from "./msl";
import pageContext from "./pageContext";
import podcast from "./podcast";
import podcasts from "./podcasts";
import podcastSubscription from "./podcastSubscription";
import eventPreAllowRegistration from "./preAllowRegistration";
import privacyPolicy from "./privacyPolicyUpdate";
import profile from "./profile";
import publications from "./publications";
import pubMeds from "./pubMeds";
import requestArticles from "./requestArticles";
import requestMaterialsOrder from "./requestMaterialsOrder";
import scheduleMeeting from "./scheduleMeeting";
import search from "./search";
import setEventAttendance from "./setEventAttendance";
import smpcProducts from "./smpcProducts";
import videoPlayer from "./videoPlayer";

export interface ApplicationState {
    analytics: State;
    askJanssen: State;
    askTheExperts: AskTheExperts;
    askTheExpertsQuestion: QuestionState;
    askTheExpertsQuestions: QuestionList;
    authValid: State;
    certificates: State;
    chat: State;
    codsId: State;
    course: State;
    courses: State;
    currentCourses: State;
    drugInteractions: State;
    drugSearch: State;
    eligibleEventNumber: State;
    eligibleEvents: State;
    event: State;
    eventPreAllowRegistration: State;
    eventsAttendance: State;
    header: { sidebarActive: boolean };
    materialCollection: MaterialSetType;
    msl: State;
    pageContext: PageContext;
    podcast: State;
    podcasts: State;
    podcastSubscription: State;
    privacyPolicy: State;
    profile: State;
    publications: State;
    pubMeds: State;
    scheduleMeeting: State;
    search: { searchTerm: string; searchResults: Record<string, unknown>[] };
    smpcProducts: { products: Array<CMSProductProps> };
    videoPlayer: { videoref: MutableRefObject<any> };
}

/**
 * The root reducer
 */
const rootReducer = combineReducers<ApplicationState>({
    analytics,
    askJanssen,
    askTheExperts,
    askTheExpertsQuestion,
    askTheExpertsQuestions,
    authValid,
    automaticallyRegisterForEvent,
    certificates,
    chat,
    codsId,
    course,
    courses,
    currentCourses,
    drugInteractions,
    drugSearch,
    eligibleEventNumber,
    eligibleEvents,
    event,
    eventPreAllowRegistration,
    eventRegistration,
    eventsAttendance,
    header,
    materialCollection,
    msl,
    pageContext,
    podcast,
    podcasts,
    podcastSubscription,
    privacyPolicy,
    profile,
    publications,
    pubMeds,
    requestArticles,
    requestMaterialsOrder,
    scheduleMeeting,
    search,
    setEventAttendance,
    smpcProducts,
    videoPlayer,
});

export default withReduxStateSync(rootReducer);
