import style from "./style.module.scss";
import React from "react";
import classnames from "classnames";

type Spacings = 0 | 4 | 8 | 16 | 24 | 32;

interface PropTypes {
    fractions: number[];
    spacing?: Spacings;
    maxTwoColumnSm?: boolean;
    minHeight?: boolean;
    children: JSX.Element | JSX.Element[];
    noMaxWidth?: boolean;
    // whether to apply top padding to the component or not
    noTopSpacing?: boolean;
}

export const FlexibleGrid = ({
    fractions,
    spacing = 16,
    children,
    maxTwoColumnSm = false,
    minHeight = true,
    noMaxWidth = false,
    noTopSpacing = false,
}: PropTypes): JSX.Element => {
    const getGridTemplateColumns = () => {
        return fractions
            .reduce((acc, current) => {
                acc += `minmax(0px, ${current}fr)`; //turns out you need to define a minimum width to avoid overflow issues
                return acc;
            }, "")
            .trim();
    };

    return (
        <div
            data-test-id="flexibleGridWrapper"
            className={classnames(
                style.row,
                minHeight && style.row_minHeight,
                style[`row-spacing-${spacing}`],
                maxTwoColumnSm && style.maxSm,
                !noMaxWidth && style.maxWidth,
                noTopSpacing && style.noTopSpacing,
            )}
            style={{ gridTemplateColumns: getGridTemplateColumns() }}
        >
            {children}
        </div>
    );
};
