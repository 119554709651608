import { JMCLink } from "@components/JMCLink/JMCLink";
import Html from "@jmc/solid-design-system/src/components/molecules/Html/Html";
import React from "react";

import style from "./style.module.scss";

interface PropTypes {
    id: string;
    url: string;
    title: string;
    linkname?: string;
}

const ExternalMenuLink: React.FunctionComponent<PropTypes> = ({ id, url, title, linkname = null }: PropTypes) => {
    return (
        <div id={id} data-linkname={linkname} data-test-id={`ExternalLink.${title}`} className={style.link}>
            <JMCLink url={url} external={true} commercial={true}>
                <Html data-test-id="Link.Title">{title}</Html>
            </JMCLink>
        </div>
    );
};

export default ExternalMenuLink;
