import React from "react";
import { components, MultiValueProps, DropdownIndicatorProps } from "react-select";
import { TagColor, Tag } from "@jmc/solid-design-system/src/components/atoms/Tag/Tag";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { Icon } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { mdiChevronDown } from "@mdi/js";
import loadable from "@loadable/component";
import { useTranslation } from "react-i18next";
import { getCustomAriaLabels, getStyle } from "./Select.Helpers";

const ReactSelect = loadable(() => import("react-select"));

export type SelectedType = ReactSelectOption | ReactSelectOption[];
export type ReactSelectOptions = ReactSelectOption[];

export interface SelectPropTypes {
    name?: string;
    placeholder?: string | JSX.Element;
    hoverColor?: string;
    disabled?: boolean;
    isSearchable?: boolean;
    onChange?: (selected: SelectedType) => void;
    onInputChange?: (text: string) => void;
    filterOption?: (option: FilterOption, rawInput: string) => boolean;
    clearValue?: () => void;
    isLoading?: boolean;
    showDropdownIndicator?: boolean;
    isClearable?: boolean;
    isMulti?: boolean;
    closeMenuOnSelect?: boolean;
    controlShouldRenderValue?: boolean;
    color?: "primary" | "secondary";
    tagColor?: TagColor;
    children?: string | JSX.Element | JSX.Element[];
    control?: {
        borderColor?: string;
        backgroundColor?: string;
        height?: string;
        borderBottomLeftRadius?: string;
        borderBottomRightRadius?: string;
        borderTopLeftRadius?: string;
        borderTopRightRadius?: string;
        borderTop?: string | number;
        boxShadow?: string;
    };
    placeholderOptions?: {
        color: string;
        // left prop: affects the horizontal position of positioned placeholder
        left?: number;
    };
    dropdownColor?: string;
    container?: {
        width: string;
        height?: string;
    };
    valueContainer?: {
        marginRight?: string;
        marginLeft?: string;
        fontSize?: string;
    };
    option?: {
        backgroundColor?: string;
        padding?: string;
        marginTop?: number | string;
        marginInline?: string;
        height?: string;
        fontWeight?: string;
        width?: string;
        lineHeight?: string;
    };
    menu?: {
        width?: string;
        borderBottomLeftRadius?: string;
        borderBottomRightRadius?: string;
        borderColor?: string;
        borderBottom?: number;
        boxShadow?: string;
        padding?: string;
        zIndex?: number;
        top?: string;
        left?: string;
        right?: string;
    };
    cursor?: string;
    error?: boolean;
    noOptionsMessage?: () => string;
    menuPlacement?: "auto" | "bottom" | "top";
    menuPosition?: "absolute" | "fixed";
    menuList?: {
        paddingTop?: string;
        paddingBottom?: string;
        maxHeight?: string;
    };
    customMenuList?: (props: any) => React.ReactElement<any, any>;
    ariaLabel?: string;
}

interface OptionPropTypes {
    value: string | number | object;
    selected?: boolean;
    searchableLabelText?: string;
    "data-test-id"?: string;
    ariaLabel?: string;
    children?: string | JSX.Element;
}

export interface ReactSelectOption {
    value: string | number | object;
    label: JSX.Element;
    isSelected?: boolean;
    searchableLabelText?: string;
    ariaLabel?: string;
}

export interface FilterOption {
    label: string;
    value: string;
    data: ReactSelectOption;
}

export const Option = (): JSX.Element => {
    return <></>;
};

/**
 * Renders a select component with multiple options.
 *
 * Accessibility notes:
 * - The `ariaLabel` prop is used to set the aria-label attribute on the select element.
 * - If your Options labels are plain strings, your don't need to pass an `ariaLabel` prop to the Option component.
 * - If your Options have JSX elements as labels, you should pass an `ariaLabel` prop to the Option component.
 */
export const Select = (props: SelectPropTypes): JSX.Element => {
    const { ...other } = props;
    const getOptions = (): ReactSelectOption[] => {
        const { children, name } = props;
        if (!children) return [];

        return React.Children.map(children, (child: React.ReactElement, index) => {
            const id =
                typeof child.props.value === "string" || typeof child.props.value === "number"
                    ? `Select.Option.${child.props.value}`
                    : `Select.Option.${index}`;
            return {
                searchableLabelText: child.props.searchableLabelText,
                value: child.props.value,
                label: child.props?.label ? (
                    child.props?.label
                ) : (
                    <Typography
                        component="span"
                        color="inherit"
                        data-test-id={child.props["data-test-id"] || id}
                        data-test-name={`Select.Option.${name}`}
                    >
                        {child.props.children}
                    </Typography>
                ),
                isSelected: child.props.selected || false,
                ariaLabel: child.props.ariaLabel || "",
            };
        });
    };

    const getSelectedOptions = (options: ReactSelectOption[]): ReactSelectOption[] => {
        if (!options) return [];
        if (other?.value?.value) {
            return options.filter((option: ReactSelectOption) => option.value === other?.value?.value);
        }
        return options.filter((option: ReactSelectOption) => option.isSelected);
    };

    const {
        placeholder = "Select item...",
        disabled = false,
        onChange = () => {},
        onInputChange = () => {},
        filterOption,
        clearValue = () => {},
        isLoading = false,
        showDropdownIndicator = true,
        isClearable = false,
        isSearchable = true,
        name,
        isMulti,
        closeMenuOnSelect = true,
        controlShouldRenderValue = true,
        tagColor = "default",
        menuPlacement = "auto",
        noOptionsMessage = () => "No option",
        customMenuList = null,
        ariaLabel,
    } = props;
    const options = getOptions();
    const selectedOptions = getSelectedOptions(options);
    const { t } = useTranslation();

    const MultiValue = (props: MultiValueProps<object>) => {
        return (
            <div {...props.removeProps}>
                <Tag data-test-id={`Tag.${(props.data as any).value}`} color={tagColor} dismissable>
                    {(props.data as any).label.props.children}
                </Tag>
            </div>
        );
    };

    const DropdownIndicator = (props: DropdownIndicatorProps<object, boolean>) => {
        return showDropdownIndicator ? (
            <components.DropdownIndicator {...props}>
                <span data-test-id="Select.DropdownIndicator" data-test-name={`DropdownIndicator.${name}`}>
                    <Icon icon={mdiChevronDown} color="inherit" verticalAlignMiddle />
                </span>
            </components.DropdownIndicator>
        ) : null;
    };

    const NoOptionsMessage = (props: any) => {
        return (
            <components.NoOptionsMessage {...props}>
                <Typography>{props.children}</Typography>
            </components.NoOptionsMessage>
        );
    };

    const MenuList = (props: any) => {
        return customMenuList ? (
            customMenuList(props)
        ) : (
            <components.MenuList {...props}>{props.children}</components.MenuList>
        );
    };

    // for easy debugging add `menuIsOpen={true}`
    return (
        <ReactSelect
            name={name}
            id={name ? `${name}Select` : "Select"}
            // menuIsOpen={true}
            styles={getStyle(props)}
            onChange={onChange}
            onInputChange={onInputChange}
            ariaLiveMessages={getCustomAriaLabels(t)}
            autosize={isMulti}
            isSearchable={isSearchable}
            isDisabled={disabled}
            placeholder={
                <Typography color="inherit" data-test-id={placeholder}>
                    {placeholder}
                </Typography>
            }
            components={{
                MultiValue,
                DropdownIndicator,
                NoOptionsMessage,
                MenuList,
            }}
            options={options}
            defaultValue={selectedOptions}
            value={selectedOptions}
            isMulti={isMulti}
            closeMenuOnSelect={closeMenuOnSelect}
            isLoading={isLoading}
            filterOption={filterOption}
            clearValue={clearValue}
            isClearable={isClearable}
            noOptionsMessage={noOptionsMessage}
            menuPlacement={menuPlacement}
            controlShouldRenderValue={controlShouldRenderValue}
            tabSelectsValue={false}
            aria-label={ariaLabel}
        />
    );
};

Select.Option = Option as React.FC<OptionPropTypes>;

export default Select;
