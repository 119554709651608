import { AxiosInstance } from "axios";
import { createStateSyncMiddleware } from "redux-state-sync";
import thunk from "redux-thunk";

import { AnyAction, applyMiddleware, createStore, Store } from "../../../../node_modules/redux";
import { create as createApi } from "../auth";
import reducer from "./modules";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ThunkDispatch<T, U, V> {}

const config = {
    // TOGGLE_TODO will not be triggered in other tabs
    predicate: (action) => action.type === "api/authValid/success",
    channel: `${process.env.BUILD_ENVIRONMENT}${process.env.BUILD_REGION}`,
};

export const create = (
    injectMiddlewares: boolean,
): Store & { dispatch: ThunkDispatch<Record<string, unknown>, AxiosInstance, AnyAction> } => {
    const middlewares = injectMiddlewares
        ? [thunk.withExtraArgument(createApi()), createStateSyncMiddleware(config)]
        : [thunk.withExtraArgument(createApi())];
    const storeFactory = applyMiddleware(...middlewares)(createStore);
    const store = storeFactory(
        reducer,
        (typeof window !== "undefined" &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION__ && // eslint-disable-line @typescript-eslint/no-explicit-any
            (window as any).__REDUX_DEVTOOLS_EXTENSION__()) || // eslint-disable-line @typescript-eslint/no-explicit-any
            undefined,
    );

    if (typeof window !== "undefined") {
        // make the store available for testing
        (window as any).appStore = store;
    }

    return store;
};
