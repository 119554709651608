import style from "./style.module.scss";
import React from "react";
import classnames from "classnames";
import { Icon, IconColorsType } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";

interface PropTypes {
    background?: string;
    color?: IconColorsType;
    description?: string;
    icon?: string;
    imageUrl?: string;
    name?: string;
    size?: "xs" | "small" | "medium" | "large" | "xl" | "xxl";
    title?: string;
    avatarInitials?: string;
    dataTestId?: string;
}

interface InitialsPropTypes {
    alt: string;
    background?: string;
    color?: string;
    name: string;
    rounded?: boolean;
    title?: string;
    avatarInitials?: string;
}

/**
 * Initials
 *
 * Using the default colors from the design system
 */
const Initials = ({ alt = "", color = "#fff", name, title = null, avatarInitials }: InitialsPropTypes) => {
    const getInitials = (name: string) => {
        let initials = "";
        const names = name.split(" ");
        initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };
    const avatarInitialsValue = avatarInitials ? avatarInitials : name;
    const ariaLabel = `${title || name || avatarInitialsValue} ${alt}`;

    return (
        <div
            className={classnames(style.text, style.initials)}
            aria-label={ariaLabel}
            style={{ color: color }}
            data-test-id={`Avatar.Letters.${avatarInitialsValue}`}
        >
            {getInitials(avatarInitialsValue)}
        </div>
    );
};

/**
 * Avatar
 */
export const Avatar = ({
    background,
    color,
    description = "",
    icon,
    imageUrl,
    name,
    size = "medium",
    title = null,
    avatarInitials,
    dataTestId = "Avatar",
}: PropTypes): JSX.Element => {
    const inlineStyle = background ? { style: { background: background } as React.CSSProperties } : null;

    return (
        <div className={classnames(style.element, style[size])} data-test-id={dataTestId} {...inlineStyle}>
            {imageUrl ? (
                <img
                    src={imageUrl}
                    data-test-id={`Avatar.Image.${imageUrl}`}
                    alt={description || ""}
                    {...(title ? { title: title } : {})}
                />
            ) : icon ? (
                <Icon icon={icon} color={color ? color : "white"} size={size} />
            ) : avatarInitials ? (
                <Initials
                    name={name}
                    alt={description || ""}
                    title={title}
                    avatarInitials={avatarInitials}
                    color={color}
                />
            ) : name ? (
                <Initials color={color} name={name} alt={description || ""} title={title} />
            ) : null}
        </div>
    );
};
