import { Flag } from "@jmc/solid-design-system/src/components/atoms/Flag/Flag";
import { FilterOption, Select } from "@jmc/solid-design-system/src/components/atoms/Select/Select";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { getCountry } from "@jmc/solid-design-system/src/utils/countries";
import { getLanguage } from "@jmc/solid-design-system/src/utils/languages";
import React from "react";

import style from "./style.module.scss";

interface Props {
    currentCountry: string;
    countryCodes: string[];
    selectedCountry: string;
    onChange: (country: string) => void;
}

export const CountryDropdown: React.FunctionComponent<Props> = ({
    currentCountry,
    countryCodes,
    selectedCountry,
    onChange,
}: Props): JSX.Element => {
    return (
        <Select
            placeholder="Select country"
            color="primary"
            onChange={({ value }) => onChange(value)}
            filterOption={(option: FilterOption, rawInput: string) =>
                option.value.toLowerCase().includes(rawInput.toLowerCase()) ||
                option.data.searchableLabelText.toLowerCase().includes(rawInput.toLowerCase())
            }
        >
            {countryCodes.map((code) => {
                let countryCode = code;
                let languageCode = "";
                if (code.includes("-")) {
                    const splitted = code.split("-");
                    countryCode = splitted[1]?.toUpperCase();
                    if (countryCode.toUpperCase() !== currentCountry?.toUpperCase()) {
                        languageCode = splitted[0]?.toUpperCase();
                    }
                }

                const country = getCountry(countryCode.toUpperCase()) || { countryName: "unknown country name" };
                const language = languageCode ? getLanguage(languageCode.toLowerCase())?.name : "";

                return (
                    <Select.Option
                        key={code}
                        value={code}
                        selected={selectedCountry === code}
                        searchableLabelText={country.countryName}
                    >
                        <div className={style.flagOption}>
                            <div className={style.flagOption__flag}>
                                <Flag code={countryCode} />
                            </div>
                            <Typography color="inherit">
                                {language ? `${country.countryName} (${language})` : country.countryName}
                            </Typography>
                        </div>
                    </Select.Option>
                );
            })}
        </Select>
    );
};
