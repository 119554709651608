const LoginToView = React.lazy(() => import("@components/LoginToView/LoginToView"));
import { LinkClickImpression } from "@jmc/core/src/components/LinkClickImpression/index";
import { useLocale } from "@jmc/core/src/hooks/useLocale/index";
import urlHelper from "@jmc/solid-design-system/src/utils/url-helper";
import { cleanCSSIdentifier } from "@jmc/utils/utils/clean-css-identifier";
import fileHelper from "@jmc/utils/utils/file-helper";
import { default as stripSlash, stripTrailing } from "@jmc/utils/utils/strip-slash";
import { globalHistory, useLocation } from "@reach/router";
import { ValidAuthResults } from "@redux/modules/authValid";
import { isAuthorized } from "@utils/authorizer";
import classnames from "classnames";
import { Link } from "gatsby";
import isEqual from "lodash/isEqual";
import React, { ReactNode, Suspense, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { SERVICES_URLS_MAPPINGS } from "../../../plugins/JMCPageCreator/Constants";
import { AccessLevel } from "../../types/AccessLevel";
import { PageContext } from "../../types/PageContext";
import { JMCLinkContext } from "./JMCLinkProvider/JMCLinkProvider";
const ExternalEventDisclaimer = React.lazy(() =>
    import("./ExternalEventDisclaimer/ExternalEventDisclaimer").then((m) => ({ default: m.ExternalEventDisclaimer })),
);
const ExternalLinkDisclaimer = React.lazy(() =>
    import("./ExternalLinkDisclaimer/ExternalLinkDisclaimer").then((m) => ({ default: m.ExternalLinkDisclaimer })),
);
const MedicalContentDisclaimer = React.lazy(() =>
    import("./MedicalContentDisclaimer/MedicalContentDisclaimer").then((m) => ({
        default: m.MedicalContentDisclaimer,
    })),
);
import anchorScroll from "@jmc/core/src/utils/anchor-scroll";

import style from "./style.module.scss";

interface JMCLinkProps {
    url: string;
    params?: { [key: string]: string | string[] } | string | string[][];
    external?: boolean;
    commercial?: boolean;
    scientific?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
    children: ReactNode;
    isAllowedOnMedical?: boolean;
    warnAbout201Links?: boolean;
    anchor_id?: string;
    isEvent?: boolean;
    isFileAsset?: boolean;
    eventNumber?: string;
    updateAttendance?: () => void;
    placement?: string;
    name?: string;
    imageLink?: boolean;
    target?: string;
    htmlLink?: boolean;
    noImpression?: boolean;
    id?: string;
    tabIndex?: number;
    key?: string;
    "data-test-id"?: string;
    style?: object;
    title?: string;
    ariaLabel?: string;
    closeOverlay?: boolean;
}

interface BaseLinkProps extends JMCLinkProps {
    anchor: string;
    finalUrl: string;
    "data-tracking-event"?: string;
    "data-tracking-info"?: object;
    onMouseDown?: (e: any) => void;
}

export const regexAnonymizeEmail = /(?!.*:).*@/;

/**
 * Wraps the Gatsby Link component (which prefetches links) and adds 201/301 business logic.
 *
 * Pages are divided into two regulatory states (set in the CMS):
 * - Promotional Content (aka 201)
 * - Medical Content (aka 301)
 * EU regulations enforce a strict separation between those two, which is interpreted by JnJ Regulatory teams as the following bss rules:
 *
 * Whenever a user navigates from a 201 page to a 301 page, we need to first show a notification (modal dialog) and then open the page in a new browser tab.
 * Navigating back from a 301 page to a 201 page is usually not permitted, but we do render them with a warning on non-prod environments.
 *
 * Additionally, this component shows a disclaimer when navigating to an external domain.
 */

export const BaseLink = ({
    params,
    external = false,
    commercial = true,
    scientific = false,
    fullWidth = true,
    disabled = false,
    children,
    isAllowedOnMedical = false,
    warnAbout201Links = true,
    isEvent = false,
    finalUrl,
    anchor,
    target,
    htmlLink = false,
    eventNumber = null,
    tabIndex = null,
    isFileAsset = false,
    ariaLabel,
    title,
    closeOverlay = false,
    ...other
}: BaseLinkProps): JSX.Element => {
    const dynamicPageContext = useSelector(({ pageContext }: { pageContext: PageContext }) => pageContext, isEqual);
    const authData = useSelector(
        ({ authValid }: { authValid: { data: ValidAuthResults } }) => authValid?.data,
        isEqual,
    );

    const locale = useLocale() || "en-us";
    const [openModal, setOpenModal] = useState(false);
    const [openLogin, setOpenLogin] = useState(false);

    const Location = useLocation();
    const classNames = classnames(style.element, disabled ? style.disabled : null, fullWidth ? style.fullWidth : null);

    const commercialContext = useContext(JMCLinkContext);

    const commercialContent = dynamicPageContext?.commercial_content
        ? dynamicPageContext.commercial_content
        : commercialContext;

    const mapUnderscoreUrl = (url: string): string => {
        const SERVICES_REGEX = /(.*)(\/services\/[^/]*)(.*)/;
        const servicesRegexResult = SERVICES_REGEX.exec(finalUrl);
        if (servicesRegexResult) {
            const serviceUrl = stripTrailing(servicesRegexResult[2]);
            const isFakeProductGlossaryDetailPage =
                serviceUrl === "/services/product_glossary" && servicesRegexResult[3];
            if (serviceUrl && isFakeProductGlossaryDetailPage) {
                return url;
            }
            const mappedUrl = SERVICES_URLS_MAPPINGS[serviceUrl];
            if (mappedUrl) {
                return url.replace(serviceUrl, mappedUrl);
            }
        }
        return url;
    };

    const currentPage =
        !external &&
        (stripTrailing(globalHistory?.location?.pathname) ===
            stripTrailing(new URL(finalUrl, process.env.GATSBY_APP_DOMAIN).pathname) ||
            finalUrl.replace(`#${anchor}`, "") === "");

    const defaultId = `Link.${finalUrl.split("/").pop()}`;

    //used to strip all but the current service page you're on from the url
    const getServiceUrl = (value: string): string => {
        return value.slice(0, value.lastIndexOf("/")).replace(`/${locale}`, "");
    };

    const onClick = (e: React.MouseEvent<HTMLElement, MouseEvent> | React.TouchEvent): void => {
        if (anchor && currentPage) {
            e.preventDefault();
            history.replaceState(null, "", anchor?.startsWith("#") ? anchor : "#" + anchor);
            // Dispachting an event cause the replaceState call used here
            // wont trigger a change in location or history objects.
            window.dispatchEvent(new Event("hashchange"));
            document.dispatchEvent(new CustomEvent("recalc", { detail: anchor }));
            anchorScroll(anchor);
        }
    };

    const closeLogin = (): void => {
        setOpenLogin(false);
    };

    const openLoginModal = (): void => {
        setOpenLogin(true);
    };

    const openDisclaimer = (): void => {
        setOpenModal(true);
    };

    //if link is disabled
    if (disabled) {
        return (
            <a
                className={classnames(htmlLink ? style.htmlLink : "", classNames)}
                data-test-id={other?.["data-test-id"] || defaultId}
                data-test-info="internal-disabled-link"
                key={other?.key}
                style={other?.style}
                data-tracking-event={other?.["data-tracking-event"]}
                data-tracking-info={other?.["data-tracking-info"]}
                onMouseDown={other?.onMouseDown}
            >
                {children}
            </a>
        );
    }

    if (external && isEvent) {
        return (
            <Suspense fallback={<div />}>
                <ExternalEventDisclaimer //if external
                    data-test-id={other?.["data-test-id"] || defaultId}
                    data-test-info="external-event-link"
                    {...other}
                    url={finalUrl}
                    openModal={openModal}
                    className={classnames(htmlLink ? style.htmlLink : "", classNames)}
                    onClose={(): void => setOpenModal(false)}
                    onClick={(): void => {
                        if (closeOverlay) {
                            (document?.getElementById("dialogMaterialOrder") as HTMLDialogElement)?.close();
                        }
                        setOpenModal(true);
                    }}
                >
                    {children}
                </ExternalEventDisclaimer>
            </Suspense>
        );
    }

    if (external && isFileAsset) {
        const accessLevel = fileHelper.getFileAccessLevel(finalUrl);
        const hasAccess = isAuthorized(authData, accessLevel, finalUrl, null, null, true);

        return (
            <>
                {openLogin && (
                    <Suspense fallback={<div />}>
                        <LoginToView
                            snackbar={closeLogin}
                            url={finalUrl}
                            navigateBackOnCancel={false}
                            anonymously={accessLevel === AccessLevel.level2}
                            forceNewTab
                        />
                    </Suspense>
                )}
                <a
                    className={classnames(htmlLink ? style.htmlLink : "", classNames)}
                    data-test-id={other?.["data-test-id"] || defaultId}
                    data-test-info="file-asset-link"
                    key={other?.key}
                    style={other?.style}
                    data-tracking-event={other?.["data-tracking-event"]}
                    data-tracking-info={other?.["data-tracking-info"]}
                    onClick={
                        !hasAccess
                            ? () => {
                                  if (closeOverlay) {
                                      (document?.getElementById("dialogMaterialOrder") as HTMLDialogElement)?.close();
                                  }
                                  openLoginModal();
                              }
                            : null
                    }
                    href={hasAccess ? finalUrl : undefined}
                    target="_blank"
                    rel="noreferrer"
                >
                    {children}
                </a>
            </>
        );
    }

    //if link is external with the exception of links to email
    if (external && !finalUrl.startsWith("mailto")) {
        return (
            <Suspense fallback={<div />}>
                <ExternalLinkDisclaimer
                    data-test-id={other?.["data-test-id"] || defaultId}
                    data-test-info="external-link"
                    tabIndex={tabIndex}
                    {...other}
                    url={finalUrl}
                    openModal={openModal}
                    className={classnames(htmlLink ? style.htmlLink : "", style.element)}
                    onClose={(): void => {
                        setOpenModal(false);
                    }}
                    onClick={(): void => {
                        if (closeOverlay) {
                            (document?.getElementById("dialogMaterialOrder") as HTMLDialogElement)?.close();
                        }
                        setOpenModal(true);
                    }}
                    ariaLabel={ariaLabel}
                    title={title}
                >
                    {children}
                </ExternalLinkDisclaimer>
            </Suspense>
        );
    }

    //if link is on commercial page and link to commercial content
    if (((commercialContent || isAllowedOnMedical) && commercial) || finalUrl.startsWith("mailto")) {
        return urlHelper.getParamStr(params) || finalUrl.startsWith("mailto") ? (
            <a
                className={classnames(htmlLink ? style.htmlLink : "", classNames)}
                href={finalUrl}
                data-test-id={other?.["data-test-id"] || defaultId}
                data-test-info="internal-link-with-params"
                key={other?.key}
                style={other?.style}
                data-tracking-event={other?.["data-tracking-event"]}
                data-tracking-info={other?.["data-tracking-info"]}
                onMouseDown={other?.onMouseDown}
            >
                {children}
            </a>
        ) : target ? (
            <a
                className={classnames(htmlLink ? style.htmlLink : "", classNames)}
                href={finalUrl}
                target={target}
                data-test-id={other?.["data-test-id"] || defaultId}
                data-test-info="internal-link-with-target"
                key={other?.key}
                style={other?.style}
                data-tracking-event={other?.["data-tracking-event"]}
                data-tracking-info={other?.["data-tracking-info"]}
                onMouseDown={other?.onMouseDown}
            >
                {children}
            </a>
        ) : (
            <Link
                state={{ prevUrl: Location.href }}
                className={classnames(htmlLink ? style.htmlLink : "", classNames)}
                to={mapUnderscoreUrl(finalUrl)}
                onClick={currentPage ? onClick : null}
                data-test-id={other?.["data-test-id"] || defaultId}
                data-test-info="internal-link"
                tabIndex={tabIndex}
                {...other}
            >
                {children}
            </Link>
        );
    }

    // if link is on commercial page and is medical
    if ((commercialContent || isAllowedOnMedical) && !commercial) {
        //For quickevents, The external link can be an absolute url, To handle that using the below logic
        const urlValue = urlHelper.isUrlAbsolute(finalUrl)
            ? finalUrl
            : `${stripSlash.strip(process.env.GATSBY_APP_DOMAIN)}${
                  // should always have a slash as the APP_DOMAIN is always in front of it
                  // making it absolute
                  finalUrl.startsWith("/") ? finalUrl : "/" + finalUrl
              }`;
        return (
            <>
                {openLogin && (
                    <Suspense fallback={<div />}>
                        <LoginToView
                            snackbar={closeLogin}
                            medical
                            scientific={scientific}
                            url={urlValue}
                            // if this login to view shows up it mean the login disclaimer is showing up on one of our page after clicking on a link to another one of our page,
                            // it therefore make sense we should stay on the current page on cancel
                            navigateBackOnCancel={false}
                        />
                    </Suspense>
                )}
                <Suspense fallback={<div />}>
                    <MedicalContentDisclaimer
                        url={urlValue}
                        anchor_id={!external && anchor}
                        openModal={openModal}
                        onClose={(): void => setOpenModal(false)}
                        onClick={(e): void => {
                            e.stopPropagation();
                            if (closeOverlay) {
                                (document?.getElementById("dialogMaterialOrder") as HTMLDialogElement)?.close();
                            }
                            //check if you're authorized for the target url, event or the entire service
                            //if so only show medical disclaimer, if not show combined login & disclaimer
                            isAuthorized(
                                authData,
                                AccessLevel.level3,
                                finalUrl.replace(`/${locale}`, ""),
                                eventNumber,
                            ) || isAuthorized(authData, AccessLevel.level3, getServiceUrl(finalUrl), eventNumber)
                                ? openDisclaimer()
                                : openLoginModal();
                        }}
                        style={{ height: "100%" }}
                        disclaimerType={isEvent ? "event" : scientific ? "scientific" : "medical"}
                    >
                        <span
                            className={classnames(htmlLink ? style.htmlLink : "", classNames)}
                            key={other?.key}
                            data-test-id={other?.["data-test-id"]}
                            style={other?.style}
                            data-tracking-event={other?.["data-tracking-event"]}
                            data-tracking-info={other?.["data-tracking-info"]}
                            onMouseDown={other?.onMouseDown}
                        >
                            <a
                                href={urlValue}
                                onClick={(e) => e.preventDefault()}
                                className={style.link}
                                data-test-id={other?.["data-test-id"] || defaultId}
                                data-test-info="201-to-301"
                                target="_blank"
                                rel="noreferrer"
                                tabIndex={tabIndex}
                            >
                                {children}
                            </a>
                        </span>
                    </MedicalContentDisclaimer>
                </Suspense>
            </>
        );
    }

    // if link is on medical page going to another medical page
    if (!commercialContent && !commercial) {
        return (
            <Link
                state={{ loginPageToPrevious: Location.href }}
                className={classnames(htmlLink ? style.htmlLink : "", classNames)}
                to={mapUnderscoreUrl(finalUrl)}
                onClick={(e) => {
                    !external && currentPage
                        ? (): void => {
                              // hacky way to avoid the MedicalControl from showing the Medical Content Disclaimer by making sure that
                              // the following onRouteUpdate call will set an non-empty window.previousPath
                              window.locations.push(`${process.env.GATSBY_APP_DOMAIN}${finalUrl}`);
                              onClick(e);
                          }
                        : (): void => {
                              window.locations.push(`${process.env.GATSBY_APP_DOMAIN}${finalUrl}`);
                          };
                }}
                data-test-id={other?.["data-test-id"] || defaultId}
                data-test-info="301-to-301"
                {...other}
            >
                {children}
            </Link>
        );
    }

    // if a medical page links to commercial page (only Germany claims to need this)
    if (warnAbout201Links && !commercialContent && commercial) {
        const linkComponent = (
            <Link
                className={classnames(htmlLink ? style.htmlLink : "", classNames)}
                to={mapUnderscoreUrl(finalUrl)}
                onClick={
                    !external && currentPage
                        ? (e): void => {
                              onClick(e);
                          }
                        : () => undefined
                }
                data-test-id={other?.["data-test-id"] || defaultId}
                data-test-info="301-to-201"
                {...other}
            >
                {children}
            </Link>
        );
        return !finalUrl.startsWith("#") ? (
            <div
                data-test-id="warning-style"
                className={classnames(
                    // only show warnings on non-prod environments, but display links inline on prod
                    ["prod"].includes(process.env.GATSBY_ENVIRONMENT) ? style.displayInline : style.warning,
                )}
            >
                {linkComponent}
            </div>
        ) : (
            linkComponent
        );
    }

    return null;
};

export const JMCLink = (props: JMCLinkProps): JSX.Element => {
    const {
        disabled,
        url,
        external,
        params,
        anchor_id = "",
        placement,
        name,
        imageLink,
        noImpression = false,
        tabIndex = null,
    } = props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { noImpression: discard, imageLink: d, ...baseProps } = props;

    const anchor = cleanCSSIdentifier(anchor_id);
    const locale = useLocale();
    const authData = useSelector(
        ({ authValid }: { authValid: { data: ValidAuthResults } }) => authValid?.data,
        isEqual,
    );
    const initialUrl = urlHelper.getUrl(url, locale, external, params);
    const [finalUrl, setFinalUrl] = useState(urlHelper.getUrl(url, locale, external, params));
    const codsId = authData?.results?.find((result) => result.codsId)?.codsId;

    const hashCodsId = "{{SHA-256-CODSID}}";
    const realCodsId = "{{CODSID}}";

    // Hide email from analytics for mailto links.
    let anonymizedUrl = "";
    const mailtoUrl = url?.startsWith("mailto");
    const phoneNumberUrl = url?.startsWith("tel:");
    if (mailtoUrl) {
        anonymizedUrl = finalUrl.replace(regexAnonymizeEmail, "email@");
        if (anonymizedUrl.includes("?")) anonymizedUrl = anonymizedUrl.slice(0, anonymizedUrl.indexOf("?"));
    }

    if (phoneNumberUrl) {
        anonymizedUrl = `phone-number`;
    }

    useEffect(() => {
        if (url) {
            setFinalUrl(urlHelper.getUrl(url, locale, external, params));
        }
    }, [url]);

    useEffect(() => {
        let unmounted = false;
        async function sh256CodsId(codsId: string): Promise<string> {
            const encoder = new TextEncoder();
            const data = encoder.encode(codsId);
            const hash = await window.crypto.subtle.digest("SHA-256", data);
            const hashArray = Array.from(new Uint8Array(hash)); // convert buffer to byte array
            const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
            return hashHex;
        }

        if ((initialUrl.includes(hashCodsId) || initialUrl.includes(realCodsId)) && codsId !== undefined) {
            sh256CodsId(codsId).then((sh256) => {
                if (unmounted) return;
                setFinalUrl(initialUrl.replace(hashCodsId, sh256).replace(realCodsId, codsId));
            });
        } else if (codsId === undefined) {
            if (unmounted) return;
            setFinalUrl(initialUrl.replace(realCodsId, "").replace(hashCodsId, ""));
        }
        return () => {
            unmounted = true;
        };
    }, [codsId]);

    useEffect(() => {
        let unmounted = false;
        if (anchor && !external) {
            if (unmounted) return;
            setFinalUrl(finalUrl + "#" + anchor);
        }
        return () => {
            unmounted = true;
        };
    }, []);

    if (disabled) return <BaseLink {...props} finalUrl={finalUrl} anchor={anchor} />;
    return noImpression ? (
        <BaseLink {...baseProps} finalUrl={finalUrl} anchor={anchor} tabIndex={tabIndex} />
    ) : (
        <LinkClickImpression
            name={mailtoUrl ? name?.replace(regexAnonymizeEmail, "email@") : phoneNumberUrl ? anonymizedUrl : name}
            targetUrl={
                mailtoUrl
                    ? anonymizedUrl
                    : phoneNumberUrl
                    ? `callto ${anonymizedUrl}`
                    : external
                    ? url
                    : urlHelper.getFullUrl(finalUrl, locale, external)
            }
            imageLink={imageLink}
            external={external}
            placement={placement}
        >
            <BaseLink {...baseProps} finalUrl={finalUrl} anchor={anchor} tabIndex={tabIndex} />
        </LinkClickImpression>
    );
};
