import { JMCLink } from "@components/JMCLink/JMCLink";
import { Icon } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import Html from "@jmc/solid-design-system/src/components/molecules/Html/Html";
import { mdiLock } from "@mdi/js";
import { ValidAuthResults } from "@redux/modules/authValid";
import {
    ArticleLink,
    AskTheExpertsLink,
    AudioLink,
    CMS_PROMOTIONAL_CONTENT,
    CourseLink,
    DrugbankLink,
    EventCenterLink,
    EventLink,
    ExternalResourceLink,
    IodLink,
    LmsLink,
    MaterialLink,
    MediaCenterLink,
    MenuItemVisibility,
    MyJmcLink,
    NewsCenterLink,
    PageLink,
    PodcastCenterLink,
    ProductGlossaryLink,
    PubmedLink,
    RequestMaterialsLink,
    ScientificPublicationDetailsLink,
    ScientificPublicationLink,
    ServiceOverviewLink,
    SpecialtiesLink,
    TcpLink,
    TransferOfValueLink,
    VideoLink,
} from "@types";
import { isAuthorized } from "@utils/authorizer";
import { getInteralPageLinkParams, getInternalPageLinkAcessLevel, getInternalPageLinkUrl } from "@utils/menu-helper";
import classnames from "classnames";
import React, { useEffect, useState } from "react";

import { SCIENTIFIC_PUBLICATIONS_URL } from "../../../../plugins/JMCPageCreator/Constants";
import style from "./style.module.scss";

interface PropTypes {
    id: string;
    title: string;
    reference:
        | PageLink
        | ArticleLink
        | AudioLink
        | CourseLink
        | EventCenterLink
        | EventLink
        | ExternalResourceLink
        | IodLink
        | LmsLink
        | MaterialLink
        | MediaCenterLink
        | MyJmcLink
        | NewsCenterLink
        | PodcastCenterLink
        | ProductGlossaryLink
        | PubmedLink
        | RequestMaterialsLink
        | ScientificPublicationDetailsLink
        | ScientificPublicationLink
        | ServiceOverviewLink
        | SpecialtiesLink
        | TransferOfValueLink
        | VideoLink
        | TcpLink
        | AskTheExpertsLink
        | DrugbankLink;
    visibility: MenuItemVisibility;
    anchorId?: string;
    params?: { key: string; value: string }[];
    authData: ValidAuthResults;
    isFirstLevel?: boolean;
    linkname?: string;
}

const hasAccess = (
    reference:
        | PageLink
        | ArticleLink
        | AudioLink
        | CourseLink
        | EventCenterLink
        | EventLink
        | ExternalResourceLink
        | IodLink
        | LmsLink
        | MaterialLink
        | MediaCenterLink
        | MyJmcLink
        | NewsCenterLink
        | PodcastCenterLink
        | ProductGlossaryLink
        | PubmedLink
        | RequestMaterialsLink
        | ScientificPublicationDetailsLink
        | ScientificPublicationLink
        | ServiceOverviewLink
        | SpecialtiesLink
        | TransferOfValueLink
        | VideoLink
        | TcpLink
        | AskTheExpertsLink
        | DrugbankLink,
    authData: ValidAuthResults,
): boolean => {
    const url = getInternalPageLinkUrl(reference);
    const access_level = getInternalPageLinkAcessLevel(reference, url);
    return isAuthorized(authData, access_level, url, (reference as EventLink)?.event_id);
};

const shouldDisplay = (hasAccess: boolean, visibility: MenuItemVisibility): boolean => {
    return hasAccess || visibility === MenuItemVisibility.DISPLAY_ITEM_WITH_LOCK;
};

const InternalMenuLink: React.FunctionComponent<PropTypes> = ({
    id,
    title,
    reference,
    visibility,
    anchorId,
    params,
    authData,
    isFirstLevel = false,
    linkname = null,
}: PropTypes) => {
    const url = getInternalPageLinkUrl(reference);
    const access_level = getInternalPageLinkAcessLevel(reference, url);
    // if it's a scientific publication it's medical (sp_detail_id)
    // if it has a regulatory status, use that
    // if it doesn't have a regulatory status, assume it's commercial
    const determineCommercial = (): boolean => {
        if ((reference as ScientificPublicationDetailsLink)?.sp_detail_id) {
            return false;
        } else if (
            reference?.regulatory_status?.promotional_or_medical !== CMS_PROMOTIONAL_CONTENT &&
            reference?.regulatory_status?.promotional_or_medical !== undefined
        ) {
            return false;
        }
        return true;
    };
    const [commercial, setCommercial] = useState(determineCommercial());
    const [displayLink, setDisplayLink] = useState(shouldDisplay(hasAccess(reference, authData), visibility));

    useEffect(() => {
        // if event, check if medical via axios call
        const determineCommercialEvent = async (): Promise<void> => {
            if ((reference as EventLink)?.event_id) {
                const response = await fetch(
                    `${process.env.GATSBY_API_ORIGIN_URL}/${process.env.GATSBY_API_VERSION}/events/medical/${
                        (reference as EventLink)?.event_id
                    }`,
                );
                const data = await response.json();
                if (data?.medical) {
                    setCommercial(false);
                }
            }
        };
        determineCommercialEvent().catch((err) => console.log(err));
    }, [reference]);

    useEffect(() => {
        setDisplayLink(shouldDisplay(hasAccess(reference, authData), visibility));
    }, [authData, reference]);

    if (!displayLink) {
        return null;
    }

    return (
        <div
            id={id}
            data-linkname={linkname}
            data-test-id={`InternalLink.${title}`}
            className={classnames(style.link, !hasAccess(reference, authData) ? style.showLock : null)}
            tabIndex={-1}
        >
            <JMCLink
                params={params && getInteralPageLinkParams(params)}
                disabled={!anchorId && !access_level && !url} //disabled if link is not published or not attached with any page.
                url={url}
                external={false}
                commercial={commercial}
                scientific={url?.includes(SCIENTIFIC_PUBLICATIONS_URL) || false}
                isEvent={(reference as EventLink)?.event_id ? true : false}
                anchor_id={anchorId || ""}
            >
                <Html data-test-id="Link.Title">{title}</Html>

                <Icon
                    icon={mdiLock}
                    size="xs"
                    className={classnames(isFirstLevel ? style.firstLevel : null, style.lock)}
                    color="text-dark"
                    data-test-id="Link.Lock"
                    verticalAlignMiddle
                />
            </JMCLink>
        </div>
    );
};

export { hasAccess, InternalMenuLink, shouldDisplay };
