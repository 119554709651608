import {
    AccessLevel,
    ArticleLink,
    AskTheExpertsLink,
    AudioLink,
    CMS_MEDICAL_CONTENT,
    CourseLink,
    DrugbankLink,
    EventCenterLink,
    EventLink,
    ExternalResourceLink,
    IodLink,
    LmsLink,
    MaterialLink,
    MediaCenterLink,
    MyJmcLink,
    NewsCenterLink,
    PageLink,
    PodcastCenterLink,
    PodcastLink,
    ProductGlossaryLink,
    PubmedLink,
    RequestMaterialsLink,
    ScientificPublicationDetailsLink,
    ScientificPublicationLink,
    ServiceOverviewLink,
    SpecialtiesLink,
    TcpLink,
    TransferOfValueLink,
    VideoLink,
} from "@types";

import {
    ATE_URL,
    DRUGBANK_URL,
    EVENT_URL,
    IOD_URL,
    LEARNING_CENTER_URL,
    MEDIA_CENTER_URL,
    MYJMC_URL,
    NEWS_CENTER_URL,
    PODCAST_CENTER_URL,
    PRODUCT_GLOSSARY_URL,
    PUBMED_URL,
    REQUEST_MATERIALS_URL,
    SCIENTIFIC_PUBLICATIONS_URL,
    SERVICES_URL,
    SPECIALTIES_URL,
    TOV_URL,
} from "../../../plugins/JMCPageCreator/Constants";

export const getInternalPageLinkUrl = (
    reference:
        | ArticleLink
        | AskTheExpertsLink
        | AudioLink
        | CourseLink
        | DrugbankLink
        | EventCenterLink
        | EventLink
        | ExternalResourceLink
        | IodLink
        | LmsLink
        | MaterialLink
        | MediaCenterLink
        | MyJmcLink
        | NewsCenterLink
        | PageLink
        | PodcastCenterLink
        | PodcastLink
        | ProductGlossaryLink
        | PubmedLink
        | RequestMaterialsLink
        | ScientificPublicationDetailsLink
        | ScientificPublicationLink
        | ServiceOverviewLink
        | SpecialtiesLink
        | TcpLink
        | TransferOfValueLink
        | VideoLink,
): string => {
    const url =
        ((reference as ArticleLink)?.article_id && `${NEWS_CENTER_URL}/${(reference as ArticleLink).article_id}`) ||
        ((reference as AskTheExpertsLink)?.ask_the_experts_uid && ATE_URL) ||
        ((reference as AudioLink)?.audio_id && `${MEDIA_CENTER_URL}/${(reference as AudioLink).audio_id}`) ||
        ((reference as CourseLink)?.course_id && `${LEARNING_CENTER_URL}/${(reference as CourseLink).course_id}`) ||
        ((reference as DrugbankLink)?.drug_to_drug_uid && DRUGBANK_URL) ||
        ((reference as EventCenterLink)?.event_center_uid && EVENT_URL) ||
        ((reference as EventLink)?.event_id && `${EVENT_URL}/${(reference as EventLink).event_id}`) ||
        ((reference as IodLink)?.iod_id && IOD_URL) ||
        ((reference as LmsLink)?.lms_uid && LEARNING_CENTER_URL) ||
        ((reference as MaterialLink)?.material_id &&
            `${REQUEST_MATERIALS_URL}/${(reference as MaterialLink).material_id}`) ||
        ((reference as MediaCenterLink)?.media_center_uid && MEDIA_CENTER_URL) ||
        ((reference as MyJmcLink)?.myjmc_id && MYJMC_URL) ||
        ((reference as NewsCenterLink)?.news_center_uid && NEWS_CENTER_URL) ||
        ((reference as PodcastCenterLink)?.podcast_center_uid && PODCAST_CENTER_URL) ||
        ((reference as PodcastLink)?.podcast_title &&
            `${PODCAST_CENTER_URL}/${encodeURIComponent(
                (reference as PodcastLink)?.podcast_title.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s+/g, "-"),
            )}`) ||
        ((reference as ProductGlossaryLink)?.product_glossary && PRODUCT_GLOSSARY_URL) ||
        ((reference as PubmedLink)?.pubmed_id && PUBMED_URL) ||
        ((reference as RequestMaterialsLink)?.request_materials_uid && REQUEST_MATERIALS_URL) ||
        ((reference as ScientificPublicationDetailsLink)?.sp_detail_id &&
            `${SCIENTIFIC_PUBLICATIONS_URL}/${(reference as ScientificPublicationDetailsLink).sp_detail_id}`) ||
        ((reference as ScientificPublicationLink)?.scientific_publications_uid && SCIENTIFIC_PUBLICATIONS_URL) ||
        ((reference as ServiceOverviewLink)?.service_overview_uid && SERVICES_URL) ||
        ((reference as SpecialtiesLink)?.specialties_uid && SPECIALTIES_URL) ||
        ((reference as TransferOfValueLink)?.transfer_of_value_uid && TOV_URL) ||
        ((reference as VideoLink)?.video_id && `${MEDIA_CENTER_URL}/${(reference as VideoLink).video_id}`) ||
        (reference as ExternalResourceLink)?.external_resource_url?.href ||
        (reference as PageLink)?.page_url;

    return url;
};

export const getInternalPageLinkAcessLevel = (
    reference:
        | PageLink
        | ArticleLink
        | AudioLink
        | CourseLink
        | EventCenterLink
        | EventLink
        | ExternalResourceLink
        | IodLink
        | LmsLink
        | MaterialLink
        | MediaCenterLink
        | MyJmcLink
        | NewsCenterLink
        | PodcastCenterLink
        | ProductGlossaryLink
        | PubmedLink
        | RequestMaterialsLink
        | ScientificPublicationDetailsLink
        | ScientificPublicationLink
        | ServiceOverviewLink
        | SpecialtiesLink
        | TransferOfValueLink
        | VideoLink
        | TcpLink
        | AskTheExpertsLink
        | DrugbankLink,
    url: string,
): AccessLevel => {
    const accessLevelForUrl = (url: string): AccessLevel => {
        switch (url) {
            case EVENT_URL:
            case REQUEST_MATERIALS_URL:
            case `${EVENT_URL}/${(reference as EventLink)?.event_id}`:
            case LEARNING_CENTER_URL:
            case `${LEARNING_CENTER_URL}/${(reference as CourseLink)?.course_id}`:
            case SCIENTIFIC_PUBLICATIONS_URL:
            case `${SCIENTIFIC_PUBLICATIONS_URL}/${(reference as ScientificPublicationDetailsLink)?.sp_detail_id}`:
            case MYJMC_URL:
                return AccessLevel.level3;
            default:
                return AccessLevel.level1;
        }
    };

    const access_level =
        reference?.regulatory_status?.promotional_or_medical === CMS_MEDICAL_CONTENT
            ? AccessLevel.level3
            : (reference?.access_control?.access_level as AccessLevel) || accessLevelForUrl(url);

    return access_level;
};

export const getInteralPageLinkParams = (linkParams: { key: string; value: string }[]): string => {
    let params: string;
    linkParams?.forEach(({ key, value }) => {
        if (!params) {
            params = `?${key}=${value}`;
        } else {
            params += `&${key}=${value}`;
        }
    });
    return params;
};
