import { ImageQueryResponse } from "@jmc/core/src/types/ImageQueryReponse";
import { ImagePosition } from "@jmc/solid-design-system/src/components/molecules/Card/Card";

import { CMSFootnotes } from "./CMSFootnotes";
import { CMSProductProps } from "./CMSProductProps";
import { CMSRegulatoryStatus } from "./CMSRegulatoryStatus";
import { CMSSMPCProps } from "./CMSSMPCProps";
import { CMSSpecialtyProps } from "./CMSSpecialtyProps";

export interface CMSMaterialProps {
    access_control: {
        access_level?: string;
    };
    content_tagging?: {
        content_topic?: string;
    };
    exclude_material: boolean;
    detail?: string;
    display_title?: string;
    fields?: { footnotes: CMSFootnotes[] };
    file_asset?: { url: string };
    id: string;
    image_focus: { image_focus: ImagePosition };
    locale: string;
    maximum_amount_orderable: number;
    publish_details?: { locale?: string };
    regulatory_status: CMSRegulatoryStatus;
    related_to?: (CMSSpecialtyProps | CMSProductProps)[];
    summary?: string;
    target_audience?: MaterialTargetAudience[];
    title: string;
    type?: MaterialType;
    uid: string;
    updated_at: string;
    visual?: ImageQueryResponse;
    smpc_reference: CMSSMPCProps[];
}

export enum MaterialType {
    PRESCRIBING_INFORMATION = "Prescribing information",
    SAFETY_INFORMATION = "Safety information",
    LEAFLET_BOOKLET = "Leaflet / booklet",
    SLIDEDECK_PRESENTATION = "Slidedeck / presentation",
    GUIDE_BOOK = "Guide / book",
    POSTER = "Poster",
    OTHER = "Other",
}

export enum MaterialTargetAudience {
    HCP = "HCP",
    Patient = "Patient",
}
