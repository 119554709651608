import { Event } from "@redux/modules/event";
import { PageContext } from "@types";

export interface PageTags {
    purpose: "promote" | "educate" | "support";
    service: string;
    product: string;
    emea_product: string;
    specialtytype: string;
    specialty: string;
    emea_specialty: string;
    contenttopic: { value: string; source: string };
    key_message_group: { value: string; source: string };
    key_message_sub_group: { value: string; source: string };
    type_of_interaction: string;
    auto_tag_content: string;
    molecule: string;
    objectives: string;
    topic_subcategory: string;
    ta_topic: string;
}

const getPurpose = (
    context: PageContext,
    eventState?: { loading: boolean; data: Event },
): "promote" | "educate" | "support" => {
    if (context?.isEvent && eventState?.data) {
        if (eventState?.data?.medical) {
            return "educate";
        } else {
            return "promote";
        }
    }
    return context?.purpose || (context?.commercial_content ? "promote" : "educate") || "promote";
};

const getProductProps = (context: PageContext): { product: string; emea_product: string } => {
    if (!context) {
        return { product: "unset", emea_product: "unset" };
    }
    const eventProduct = new Set<string>();
    const mediaEventProduct = new Set<string>();
    const eventEmeaProduct = new Set<string>();
    const mediaEventEmeaProduct = new Set<string>();

    try {
        if (context?.related_to && context?.related_to?.length !== 0) {
            context?.related_to?.forEach((product) => {
                try {
                    if ("related_to" in product) {
                        product.related_to.forEach((related) => {
                            if ("emea_product" in related) {
                                mediaEventProduct.add(related.productTitle);
                                mediaEventEmeaProduct.add(related.emea_product);
                            }
                        });
                    }

                    if ("emea_product" in product) {
                        //inside an product related to an event
                        eventProduct.add(product.productTitle);
                        eventEmeaProduct.add(product.emea_product);
                    }
                } catch (err) {
                    // eslint-disable-next-line no-console
                    console.log(err);
                }
            });
        }
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
    }
    return {
        product:
            context.product || Array.from(eventProduct).join("|") || Array.from(mediaEventProduct).join("|") || "unset",
        emea_product:
            context.emea_product ||
            Array.from(eventEmeaProduct).join("|") ||
            Array.from(mediaEventEmeaProduct).join("|") ||
            "unset",
    };
};

const getSpecialtyProps = (
    context: PageContext,
): { specialtytype: string; specialty: string; emea_specialty: string } => {
    if (!context) {
        return { specialtytype: "unset", specialty: "unset", emea_specialty: "unset" };
    }
    const eventSpecialtyType = new Set<string>();
    const mediaEventSpecialtyType = new Set<string>();
    const eventSpecialty = new Set<string>();
    const mediaEventSpecialty = new Set<string>();
    const eventEmeaSpecialty = new Set<string>();
    const mediaEventEmeaSpecialty = new Set<string>();

    try {
        if (context?.related_to && context?.related_to?.length !== 0) {
            context?.related_to?.forEach((product) => {
                try {
                    //is it related ot an event ?
                    if ("related_to" in product) {
                        product.related_to.forEach((related) => {
                            //is the relation a product
                            if ("emea_product" in related) {
                                related?.specialty?.forEach((s) => {
                                    mediaEventEmeaSpecialty.add(s.emea_specialty);
                                    mediaEventSpecialty.add(s.specialtyTitle);
                                    mediaEventSpecialtyType.add(s.specialtyType);
                                });
                            } else if ("emea_specialty" in related) {
                                mediaEventEmeaSpecialty.add(related.emea_specialty);
                                mediaEventSpecialty.add(related.specialtyTitle);
                                mediaEventSpecialtyType.add(related.specialtyType);
                            }
                        });
                    }
                    if ("emea_product" in product) {
                        //inside an product related to an event
                        product?.specialty?.forEach((s) => {
                            eventEmeaSpecialty.add(s.emea_specialty);
                            eventSpecialty.add(s.specialtyTitle);
                            eventSpecialtyType.add(s.specialtyType);
                        });
                    } else if ("emea_specialty" in product) {
                        //inside a speciality related to an event
                        eventEmeaSpecialty.add(product.emea_specialty);
                        eventSpecialty.add(product.specialtyTitle);
                        eventSpecialtyType.add(product.specialtyType);
                    }
                } catch (err) {
                    // eslint-disable-next-line no-console
                    console.log(err);
                }
            });
        }
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
    }
    return {
        specialtytype:
            context.specialtytype ||
            Array.from(eventSpecialtyType).join("|") ||
            Array.from(mediaEventSpecialtyType).join("|") ||
            "unset",
        specialty:
            context.specialty ||
            Array.from(eventSpecialty).join("|") ||
            Array.from(mediaEventSpecialty).join("|") ||
            "unset",
        emea_specialty:
            context.emea_specialty ||
            Array.from(eventEmeaSpecialty).join("|") ||
            Array.from(mediaEventEmeaSpecialty).join("|") ||
            "unset",
    };
};

const getPageTags = (context?: PageContext, eventState?: { loading: boolean; data: Event }): PageTags => {
    const { product, emea_product } = getProductProps(context);
    const { specialtytype, specialty, emea_specialty } = getSpecialtyProps(context);

    return {
        purpose: getPurpose(context, eventState),
        service: context?.service || "unset",
        product: product,
        emea_product: emea_product,
        specialtytype: specialtytype,
        specialty: specialty,
        emea_specialty: emea_specialty,
        type_of_interaction: context?.type_of_interaction || "unset",
        auto_tag_content: context?.auto_tag_content ?? (context?.type_of_interaction ? "off" : "on"),
        contenttopic: { value: "unset", source: "unset" },
        key_message_group: { value: "unset", source: "unset" },
        key_message_sub_group: { value: "unset", source: "unset" },
        molecule: "unset",
        objectives: "unset",
        topic_subcategory: "unset",
        ta_topic: "unset",
        ...((!context?.type_of_interaction || context?.type_of_interaction?.toLowerCase() === "commercial") && {
            contenttopic: context?.contenttopic || { value: "unset", source: "unset" },
            key_message_group: context?.key_message_group || { value: "unset", source: "unset" },
            key_message_sub_group: context?.key_message_sub_group || { value: "unset", source: "unset" },
        }),
        ...(context?.type_of_interaction?.toLowerCase() === "medical" && {
            molecule: context?.molecule || "unset",
            objectives: context?.objectives || "unset",
            topic_subcategory: context?.topic_subcategory || "unset",
            ta_topic: context?.ta_topic || "unset",
        }),
    };
};

export default getPageTags;
