import { SelectPropTypes, ReactSelectOption } from "./Select";

/**
 * Custom implementation of react-select defaultAriaLiveMessages
 * to provide translation to aria messages.
 *
 * https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/accessibility/index.ts#L103
 *
 * You can use the invertedSelection parameter if the Select component is reading the wrong state.
 * This can happen if you are storing the selected values in a state, then change in status happens before
 * the aria labels are read.
 *
 * @param t - Translation function
 * @param invertedSelection - Inverts the message for the selected state of an option
 *
 */
export const getCustomAriaLabels = (t: (key: string, params?: any) => string, invertedSelection = false) => {
    return {
        guidance: (props: {
            [x: string]: any;
            isSearchable?: any;
            isMulti?: any;
            tabSelectsValue?: any;
            context?: any;
            isInitialFocus?: any;
        }) => {
            const { isSearchable, isMulti, tabSelectsValue, context, isInitialFocus } = props;

            switch (context) {
                case "menu":
                    return t(
                        "Use Up and Down to choose options, press Enter to select the currently focused option, press Escape to exit the menu",
                        { ns: "accessibility" },
                    ).concat(
                        tabSelectsValue
                            ? "".concat(
                                  ", ",
                                  t("press Tab to select the option and exit the menu", { ns: "accessibility" }),
                              )
                            : "",
                    );
                case "input":
                    return isInitialFocus
                        ? ""
                              .concat(t("Press Down to open the menu", { ns: "accessibility" }))
                              .concat(
                                  isSearchable
                                      ? "".concat(", ", t("type to refine list", { ns: "accessibility" }))
                                      : "",
                              )
                              .concat(
                                  isMulti
                                      ? "".concat(
                                            ", ",
                                            t("press left to focus selected values", { ns: "accessibility" }),
                                        )
                                      : "",
                              )
                        : "";
                case "value":
                    return t(
                        "Use left and right to toggle between focused values, press Backspace to remove the currently focused value",
                        { ns: "accessibility" },
                    );
                default:
                    return "";
            }
        },
        onChange: (props: {
            action: any;
            label?: "";
            labels?: any[];
            isDisabled: any;
            value: ReactSelectOption;
            options: ReactSelectOption[];
            option?: ReactSelectOption;
        }) => {
            const { action, label = "", isDisabled, value, options, option } = props;
            const ariaLabel = value?.ariaLabel || label;
            const ariaLabels = options?.map((option) => option?.ariaLabel || option?.label);
            let selected = isDisabled || option?.isSelected;
            selected = invertedSelection ? !selected : selected;

            switch (action) {
                case "deselect-option":
                case "pop-value":
                case "remove-value":
                    return t("option {{LABEL}}, deselected.", { LABEL: ariaLabel, ns: "accessibility" });
                case "clear":
                    return t("All selected options have been cleared.", { ns: "accessibility" });
                case "initial-input-focus":
                    return ariaLabels.length > 0
                        ? t("options {{LABEL}}, selected.", { LABEL: ariaLabels.join(","), ns: "accessibility" })
                        : t("option {{LABEL}}, selected.", { LABEL: ariaLabels.join(","), ns: "accessibility" });
                case "select-option":
                    return selected
                        ? t("option {{LABEL}} is disabled. Select another option.", {
                              LABEL: ariaLabel,
                              ns: "accessibility",
                          })
                        : t("option {{LABEL}}, selected.", { LABEL: ariaLabel, ns: "accessibility" });

                default:
                    return "";
            }
        },
        onFocus: (props: {
            context: any;
            focused: any;
            options: any;
            label: any;
            selectValue: any;
            isDisabled: any;
            isSelected: any;
            isAppleDevice: any;
        }) => {
            const { context, focused, options, label, selectValue, isDisabled, isSelected, isAppleDevice } = props;
            const ariaLabel = focused.ariaLabel || label;

            const getArrayIndex = (arr: string[], item: string) => {
                if (arr && arr.length) {
                    return t("{{LABEL}} of {{LENGTH}}", {
                        LABEL: arr.indexOf(item) + 1,
                        LENGTH: arr.length,
                        ns: "accessibility",
                    });
                } else {
                    return "";
                }
            };

            if (context === "value" && selectValue) {
                return t("value {{LABEL}} focused, {{INDEX}}.", {
                    LABEL: ariaLabel,
                    INDEX: getArrayIndex(selectValue, focused),
                    ns: "accessibility",
                });
            }
            if (context === "menu" && isAppleDevice) {
                let status = "";
                if (isSelected) {
                    status = isDisabled
                        ? t("{{LABEL}} selected and disabled", { LABEL: ariaLabel, ns: "accessibility" })
                        : t("{{LABEL}} selected", { LABEL: ariaLabel, ns: "accessibility" });
                } else {
                    status = isDisabled
                        ? t("{{LABEL}} disabled", { LABEL: ariaLabel, ns: "accessibility" })
                        : t("{{LABEL}}", { LABEL: ariaLabel, ns: "accessibility" });
                }

                return t("{{STATUS}}, {{INDEX}}.", {
                    STATUS: status,
                    INDEX: getArrayIndex(options, focused),
                    ns: "accessibility",
                });
            }
            return "";
        },
        onFilter: (props: { inputValue: any; resultsMessage: any }) => {
            const { inputValue, resultsMessage } = props;

            if (inputValue) {
                return t("{{RESULTS}} for search term {{INPUT}}.", {
                    RESULTS: resultsMessage,
                    INPUT: inputValue,
                    ns: "accessibility",
                });
            } else return resultsMessage;
        },
    };
};

export type ColorMappings = {
    primary: string;
    secondary: string;
};

const SECONDARY_COLOR = "var(--secondary)";

const borderColorMapping: ColorMappings = {
    primary: "var(--border-color, #858585)",
    secondary: SECONDARY_COLOR,
};

const placeholderColorMapping: ColorMappings = {
    primary: "#858585",
    secondary: SECONDARY_COLOR,
};

const dropdownColorMapping: ColorMappings = {
    primary: "#606060",
    secondary: SECONDARY_COLOR,
};

export const getStyle = (props: SelectPropTypes): any => {
    const {
        color = "primary",
        error,
        control,
        placeholderOptions,
        valueContainer,
        option,
        cursor,
        menu,
        menuList,
        isMulti,
        hoverColor,
        dropdownColor,
        container,
    } = props;
    const borderColor = control?.borderColor ? control.borderColor : borderColorMapping[color];
    const controlBackground = control?.backgroundColor;
    return {
        control: (provided: any, state: any) => {
            const styles = {
                ...provided,
                outline: state.isFocused ? "5px auto -webkit-focus-ring-color" : "0 !important",
                cursor: cursor ? cursor : "default",
                backgroundColor: error
                    ? "var(--error-100)"
                    : controlBackground
                    ? controlBackground === "transparent" && state.menuIsOpen
                        ? "var(--white)"
                        : controlBackground
                    : provided.backgroundColor,
                borderColor: error ? "var(--error)" : borderColor,
                boxShadow: control?.boxShadow ? control?.boxShadow : "none",
                boxSizing: "content-box",
                borderTop: state.menuIsOpen ? control?.borderTop : "default",
                borderTopLeftRadius: control?.borderTopLeftRadius
                    ? state.menuIsOpen
                        ? 0
                        : control?.borderTopLeftRadius
                    : "var(--select-border-top-left-radius, 4px)",
                borderTopRightRadius: control?.borderTopRightRadius
                    ? state.menuIsOpen
                        ? 0
                        : control?.borderTopRightRadius
                    : "var(--select-border-top-left-radius, 4px)",
                borderBottomLeftRadius: control?.borderBottomLeftRadius
                    ? control?.borderBottomLeftRadius
                    : state.menuIsOpen
                    ? 0
                    : "var(--select-border-bottom-left-radius, 4px)",
                borderBottomRightRadius: control?.borderBottomRightRadius
                    ? control?.borderBottomRightRadius
                    : state.menuIsOpen
                    ? 0
                    : "var(--select-border-bottom-right-radius, 4px)",
                color: "var(--grey-300)",
                padding: "0",
                "&:hover": {
                    borderColor,
                },
            };

            let returnStyles = control?.height
                ? { ...styles, height: control?.height, minHeight: control?.height }
                : { ...styles };
            returnStyles =
                props.tagColor === "default"
                    ? { ...returnStyles, color: "var(--text-dark)" }
                    : { ...returnStyles, color: "var(--white)" };
            return returnStyles;
        },
        singleValue: (provided: any) => ({
            ...provided,
            color: error ? "var(--error)" : "var(--text-dark)",
            margin: 0,
        }),
        placeholder: (provided: any, state: any) => {
            const menuIsOpen = state?.selectProps?.menuIsOpen;
            const styles = {
                ...provided,
                position: "initial",
                transform: "initial",
                color: placeholderOptions?.color
                    ? !menuIsOpen
                        ? placeholderOptions?.color
                        : dropdownColor || placeholderColorMapping[color]
                    : dropdownColor || placeholderColorMapping[color],
            };
            return placeholderOptions?.left ? { ...styles, left: placeholderOptions.left } : styles;
        },
        menu: (provided: any) => {
            let styles = {
                ...provided,
                border: "1px solid",
                borderRadius: isMulti
                    ? "0 0 4px 4px"
                    : menu?.borderBottomLeftRadius && menu?.borderBottomRightRadius
                    ? "4px 4px 0 0"
                    : "4px",

                borderColor: menu?.borderColor ?? borderColor,
                boxShadow: menu?.boxShadow ? menu?.boxShadow : "none",
                margin: 0,
                zIndex: menu?.zIndex ? menu?.zIndex : "var(--layer-higher-priority)",
                borderBottom: menu?.borderBottom !== undefined ? menu?.borderBottom : "1px solid",
                padding: menu?.padding ? menu?.padding : 0,
            };
            styles = menu?.width ? { ...styles, width: menu?.width } : styles;
            styles = menu?.top ? { ...styles, top: menu?.top } : styles;
            styles = menu?.left ? { ...styles, left: menu?.left } : styles;
            styles = menu?.right ? { ...styles, right: menu?.right } : styles;

            return styles;
        },
        menuList: (provided: any) => {
            const styles = {
                ...provided,
                paddingTop: menuList?.paddingTop ?? "12px",
                paddingBottom: menuList?.paddingBottom ?? "12px",
            };
            return menuList?.maxHeight ? { ...styles, maxHeight: menuList?.maxHeight } : styles;
        },
        valueContainer: (provided: any) => {
            let styles = { ...provided };
            styles = control?.height ? { ...styles, height: control.height } : styles;
            styles = valueContainer?.marginRight ? { ...styles, marginRight: valueContainer?.marginRight } : styles;
            styles = valueContainer?.marginLeft ? { ...styles, marginLeft: valueContainer?.marginLeft } : styles;
            styles = valueContainer?.fontSize ? { ...styles, fontSize: valueContainer?.fontSize } : styles;
            return styles;
        },
        input: (provided: any) => ({
            ...provided,
            padding: 0,
            margin: 0,
        }),
        indicatorSeparator: () => ({
            display: "none",
        }),
        container: (provided: any) => ({
            ...provided,
            display: "flex",
            flexDirection: "column",
            position: "relative",
            width: container?.width || "100%",
            height: container?.height || "40px",
        }),
        dropdownIndicator: (provided: any, state: { selectProps: { menuIsOpen: boolean } }) => ({
            ...provided,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen ? "rotateX(180deg)" : null,
            color: placeholderOptions?.color
                ? state.selectProps.menuIsOpen
                    ? "var(--primary)"
                    : placeholderOptions?.color
                : dropdownColorMapping[color],
            padding: "6px",
            "&:hover": {
                color: hoverColor ? (state.selectProps.menuIsOpen ? "var(--primary)" : hoverColor) : "default",
            },
        }),
        option: (provided: any, state: { isSelected: boolean; isActive: boolean; isFocused: boolean }) => ({
            ...provided,
            fontSize: valueContainer?.fontSize ? valueContainer.fontSize : "0.875rem",
            backgroundColor: state.isFocused
                ? option?.backgroundColor
                    ? option.backgroundColor
                    : `var(--${color}-300)`
                : "none",
            color:
                (state.isSelected && state.isFocused) || state.isFocused || state.isActive ? "var(--white)" : "inherit",
            marginTop: option?.marginTop || 16,
            marginInline: option?.marginInline || 0,
            height: option?.height || "auto",
            width: option?.width || "100%",
            fontWeight: option?.fontWeight || "600",
            lineHeight: option?.lineHeight || "22px",
            padding: option?.padding || "0 var(--spacing-M)",
            borderRadius: "2px",
            "&:hover": {
                backgroundColor: option?.backgroundColor ? option.backgroundColor : `var(--${color})`,
                color: "var(--white)",
            },
            "&:active": {
                backgroundColor: option?.backgroundColor ? option.backgroundColor : `var(--${color}-400)`,
                color: "var(--white)",
            },
            "&:focus": {
                backgroundColor: option?.backgroundColor ? option.backgroundColor : `var(--${color}-300)`,
                color: "var(--white)",
                outline: "none",
            },
            "&:first-of-type": {
                marginTop: 0,
            },
        }),
    };
};
