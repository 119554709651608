import { JMCLink } from "@components/JMCLink/JMCLink";
import { Icon } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import Html from "@jmc/solid-design-system/src/components/molecules/Html/Html";
import fileHelper from "@jmc/utils/utils/file-helper";
import { mdiLock } from "@mdi/js";
import { ValidAuthResults } from "@redux/modules/authValid";
import { MenuItemVisibility } from "@types";
import { isAuthorized } from "@utils/authorizer";
import classnames from "classnames";
import React, { useEffect, useState } from "react";

import style from "./style.module.scss";

interface PropTypes {
    id: string;
    url: string;
    title: string;
    visibility: MenuItemVisibility;
    authData: ValidAuthResults;
    isFirstLevel?: boolean;
}

const hasAccess = (url: string, authData: ValidAuthResults): boolean => {
    return isAuthorized(authData, fileHelper.getFileAccessLevel(url), url, null, null, true);
};

const shouldDisplay = (hasAccess: boolean, visibility: MenuItemVisibility): boolean => {
    return hasAccess || visibility === MenuItemVisibility.DISPLAY_ITEM_WITH_LOCK;
};

const FileAssetMenuLink: React.FunctionComponent<PropTypes> = ({
    id,
    url,
    title,
    visibility,
    authData,
    isFirstLevel = false,
}: PropTypes) => {
    const [displayLink, setDisplayLink] = useState(shouldDisplay(hasAccess(url, authData), visibility));

    useEffect(() => {
        setDisplayLink(shouldDisplay(hasAccess(url, authData), visibility));
    }, [authData, url]);

    if (!displayLink) {
        return null;
    }

    return (
        <div
            id={id}
            data-test-id={`FileAssetLink.${title}`}
            className={classnames(style.link, !hasAccess(url, authData) ? style.showLock : null)}
            tabIndex={-1}
        >
            <JMCLink url={url} external={true} commercial={true} isFileAsset>
                <Html data-test-id="Link.Title">{title}</Html>
                <Icon
                    icon={mdiLock}
                    size={"xs"}
                    className={classnames(isFirstLevel ? style.firstLevel : null, style.lock)}
                    color="text-dark"
                    data-test-id="Link.Lock"
                    verticalAlignMiddle
                />
            </JMCLink>
        </div>
    );
};

export { FileAssetMenuLink, hasAccess, shouldDisplay };
