import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { JMCHtml } from "@components/JMCHtml/JMCHtml";
import { LoadingButton } from "@components/LoadingButton/LoadingButton";
import analyticstracker from "@jmc/analyticstracker";
import { useLocale } from "@jmc/core/src/hooks/useLocale";
import { Button } from "@jmc/solid-design-system/src/components/atoms/Button/Button";
import { Icon } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import NumberInput from "@jmc/solid-design-system/src/components/atoms/NumberInput/NumberInput";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { mdiArrowLeft, mdiCartOutline, mdiDeleteOutline } from "@mdi/js";
import { mdiAccountCircleOutline, mdiCheckCircleOutline, mdiClose } from "@mdi/js";
import { clear, MaterialSetType, set as setMaterialCollection } from "@redux/modules/materialCollection";
import { Profile } from "@redux/modules/profile";
import { load as submitRequestMaterialsOrder, RequestMaterialsOrder } from "@redux/modules/requestMaterialsOrder";
import { CMSLegalDocuments, CMSorderFormConfig, MaterialEventType } from "@types";
import materialHelper from "@utils/material-helper";
import trackingHelper from "@utils/tracking-helper";
import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImageProps } from "gatsby-plugin-image";
import isEqual from "lodash/isEqual";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CMSMaterialProps } from "types/CMSMaterialProps";
import { CMSMaterialOrderItem } from "types/CMSRequestMaterials";

import Logo from "../Logo";
import { OrderForm } from "./order-form/order-form";
import style from "./style.module.scss";

export interface Props {
    logo?: {
        url: string;
        gatsbyImageData?: GatsbyImageProps;
    };
}

interface MaterialCollectionHookReturn {
    order: {
        material: CMSMaterialProps;
        amount: number;
        salesRepId?: string;
    }[];
    deleteMaterialFromCollection: (
        material: CMSMaterialProps,
        index: number,
        track: boolean,
        callback: () => Promise<void>,
    ) => Promise<void>;
    onChangeMaterialAmount: (item: CMSMaterialOrderItem, amount: number, index: number) => void;
    updateCollection: () => Promise<void>;
    deleteCollection: () => Promise<void>;
}

//custom hook for abstracting data manipulation of material collection)
const useMaterialCollection = (): MaterialCollectionHookReturn => {
    const { materialCollection } = useSelector(({ materialCollection }: { materialCollection: MaterialSetType }) => ({
        materialCollection,
    }));

    const [order, setOrder] = useState(Array.from(materialCollection.values()));

    const dispatch = useDispatch();

    useEffect(() => {
        setOrder(Array.from(materialCollection.values()));
    }, [materialCollection]);

    const deleteCollection = async (): Promise<void> => {
        await dispatch(clear());
        return;
    };

    const updateCollection = async (): Promise<void> => {
        await dispatch(setMaterialCollection(materialCollection));
        return;
    };

    const deleteMaterialFromCollection = async (
        material: CMSMaterialProps,
        index: number,
        track = true,
        callback: () => Promise<void>,
    ): Promise<void> => {
        if (track) {
            analyticstracker().trackEvent({
                event: MaterialEventType.MATERIAL_REMOVE_FROM_CART,
                commerce: trackingHelper.mapMaterialImpression(material, "material checkout", index + 1),
                info: { itemtotal: 1 },
            });
        }
        materialCollection.delete(material.id);
        setOrder(Array.from(materialCollection.values()));
        if (materialCollection.size === 0) {
            await callback();
            await deleteCollection();
        }
    };

    const onChangeMaterialAmount = (item: CMSMaterialOrderItem, amount: number, index: number): void => {
        const eventType =
            materialCollection.get(item.material.id).amount < amount
                ? MaterialEventType.MATERIAL_ADD_TO_CART
                : MaterialEventType.MATERIAL_REMOVE_FROM_CART;

        materialCollection.set(item.material.id, { material: item.material, amount });

        analyticstracker().trackEvent({
            event: eventType,
            commerce: trackingHelper.mapMaterialImpression(item.material, "material checkout", index, amount),
            info: { itemtotal: 1 },
        });
    };

    return {
        order: order,
        deleteMaterialFromCollection: deleteMaterialFromCollection,
        onChangeMaterialAmount: onChangeMaterialAmount,
        updateCollection: updateCollection,
        deleteCollection: deleteCollection,
    };
};

export const MaterialOverlay = ({ logo }: Props): JSX.Element => {
    const { profile } = useSelector(
        ({ profile }: { profile: { data: Profile } }) => ({
            profile: profile.data,
        }),
        isEqual,
    );
    const [trackImpression, setTrackImpression] = useState(true);
    const { order, deleteMaterialFromCollection, onChangeMaterialAmount, updateCollection, deleteCollection } =
        useMaterialCollection();
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const scrollArea = useRef(null);
    const lastListElement = useRef(null);
    const scrollIndicator = useRef(null);
    const locale = useLocale();
    const listRef = useRef(null);
    const formRef = useRef(null);
    const messageRef = useRef(null);

    const {
        legalDocuments,
        allOrderFormConfig,
    }: { legalDocuments: CMSLegalDocuments; allOrderFormConfig: CMSorderFormConfig } = useStaticQuery(graphql`
        query LegalDocumentsAndOrderFormQuery {
            legalDocuments: allContentstackLegalDocuments {
                nodes {
                    link {
                        href
                        title
                    }
                    internal_link {
                        url
                        regulatory_status {
                            promotional_or_medical
                        }
                    }
                    title
                    locale
                    type
                }
            }
            allOrderFormConfig: allContentstackRequestMaterials {
                nodes {
                    publish_details {
                        locale
                    }
                    orderFormConfig: order_form_config {
                        address_rule
                        country_rule
                        description
                        gender_options
                        gender_rule
                        telephone_rule
                        title_options
                        title_rule
                    }
                }
            }
        }
    `);

    const localConfig = allOrderFormConfig?.nodes?.filter((node) => node.publish_details.locale === locale);
    const { orderFormConfig = {} } = localConfig[0] || {};

    const closeDialog = async (
        dialogId: Array<"dialogMaterialForm" | "dialogMaterialThankYou" | "dialogMaterialOrder">,
        update = false,
    ): Promise<void> => {
        if (update) {
            await updateCollection();
        }
        dialogId.forEach((d) => {
            switch (d) {
                case "dialogMaterialForm":
                    formRef.current.close();
                    break;
                case "dialogMaterialThankYou":
                    messageRef.current.close();
                    break;
                default:
                    listRef.current.close();
                    break;
            }
        });
    };

    const openDialog = async (
        dialogId: "dialogMaterialForm" | "dialogMaterialThankYou" | "dialogMaterialOrder",
        update = false,
    ): Promise<void> => {
        if (update) {
            await updateCollection();
        }
        switch (dialogId) {
            case "dialogMaterialForm":
                formRef.current.showModal();
                break;
            case "dialogMaterialThankYou":
                messageRef.current.showModal();
                break;
            default:
                listRef.current.showModal();
                break;
        }
    };

    useEffect(() => {
        if (trackImpression) {
            setTimeout(() => {
                analyticstracker().trackImpression(MaterialEventType.MATERIAL_CHECKOUT, {
                    addData: { info: { itemtotal: order.length } },
                });
            }, 1000); // the 1000ms makes sure we have all proper values set
            setTrackImpression(false);
        }
    }, [trackImpression]);

    //handle observer for the scrolling indicators of the list
    useEffect(() => {
        const callback = (entries: IntersectionObserverEntry[]): void => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    scrollIndicator.current?.classList?.remove("visible");
                } else {
                    scrollIndicator.current?.classList?.add("visible");
                }
            });
        };

        let observer = null;

        if (scrollArea.current) {
            observer = new IntersectionObserver(callback, { root: scrollArea.current });

            if (lastListElement.current) {
                observer.observe(lastListElement.current);
            }
        }

        return () => {
            observer.disconnect();
        };
    });

    useEffect(() => {
        const callback = async (): Promise<void> => {
            await updateCollection();
        };
        window.addEventListener("beforeunload", callback);
        return () => {
            window.removeEventListener("beforeunload", callback);
        };
    }, []);

    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const methods = useForm({
        defaultValues: {
            title: null,
            gender: null,
            firstName: profile?.firstName,
            lastName: profile?.lastName,
            email: profile?.email,
            phone: null,
            street: null,
            number: null,
            city: null,
            postalCode: null,
            country: null,
            terms: false,
        },
    });

    useEffect(() => {
        methods.reset(
            {
                title: null,
                gender: null,
                firstName: profile?.firstName || null,
                lastName: profile?.lastName || null,
                email: profile?.email || null,
                phone: null,
                street: null,
                number: null,
                city: null,
                postalCode: null,
                country: null,
                terms: false,
            },
            { keepDefaultValues: false },
        );
    }, [profile]);

    const onSubmit = async (data: any): Promise<void> => {
        setIsLoading(true);
        setErrors([]);
        // // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((window as any).recap !== false) await recaptchaRef.current.executeAsync();

        if (data?.phone?.length <= 6) data.phone = null;

        delete data.terms;
        const finalData: RequestMaterialsOrder = {
            ...data,
            materialOrders: order.map((item: CMSMaterialOrderItem) => ({
                amount: item.amount,
                key: item.material.title,
                type: materialHelper.getMaterialTypeString(item.material.type, t),
                salesRepId: item.salesRepId || null,
            })),
        };

        try {
            const dispatchResult = await dispatch(submitRequestMaterialsOrder({ data: finalData }));
            if (dispatchResult?.type?.includes("success")) {
                analyticstracker().trackEvent({
                    event: MaterialEventType.MATERIAL_PURCHASE,
                    commerce: order.map((item: CMSMaterialOrderItem, index: number) =>
                        trackingHelper.mapMaterialImpression(
                            item.material,
                            "material checkout",
                            index + 1,
                            item.amount,
                        ),
                    ),
                    info: {
                        transaction_id: dispatchResult.payload.referenceId,
                        value: order
                            .reduce((acc, current) => {
                                acc += current.amount;
                                return acc;
                            }, 0)
                            .toFixed(2),
                        itemtotal: order.length,
                    },
                });

                await deleteCollection();

                if (recaptchaRef?.current?.reset) recaptchaRef.current.reset();

                setIsLoading(false);

                await openDialog("dialogMaterialThankYou");
            } else if (dispatchResult?.type?.includes("failure")) {
                const { validationErrors } = dispatchResult.payload;
                if (validationErrors) {
                    setErrors(validationErrors);
                } else {
                    setErrors([
                        { constraints: [dispatchResult.payload.message], property: dispatchResult.payload.errorCode },
                    ]);
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.log("error while attempting to dispatch order");
        }
    };

    return (
        <>
            <dialog id="dialogMaterialOrder" className={style.dialog} data-test-id="dialogMaterialOrder" ref={listRef}>
                <div className={style.overlayContent}>
                    <div className={style.flexContainer}>
                        <Logo logo={logo} />
                        <div
                            onClick={async () => {
                                await closeDialog(["dialogMaterialOrder"], true);
                            }}
                        >
                            <span className={style.closeText} data-test-id="closeTextList">
                                {t("common:Close")}
                            </span>
                            <Icon icon={mdiClose} color="primary" size="large" verticalAlignMiddle={true} />
                        </div>
                    </div>
                    <div className={classNames(style.inner, style.center)}>
                        <span className={style.intro}>
                            <Icon icon={mdiCartOutline} size="medium" verticalAlignMiddle />
                            <Typography variant="h6">{t("Your free order overview", { ns: "materials" })}</Typography>
                        </span>
                        <hr />
                        <div style={{ position: "relative" }}>
                            <ul id="materialOverlayList" ref={scrollArea}>
                                {orderFormConfig?.description && (
                                    <JMCHtml
                                        id="form-description"
                                        data-test-id="dialogMaterialOrderDescription"
                                        closeOverlay
                                    >
                                        {orderFormConfig.description}
                                    </JMCHtml>
                                )}
                                {order.map((item: CMSMaterialOrderItem, index: number) => (
                                    <li key={item.material.id} id={item.material.id}>
                                        <div className={style.material} data-test-id={item.material.id}>
                                            {item.material.visual && (
                                                <img src={item.material.visual.url} className={style.image} />
                                            )}
                                            <div className={style.content}>
                                                <JMCHtml footnotes={item?.material?.fields?.footnotes}>
                                                    {item.material.display_title || item.material.title}
                                                </JMCHtml>
                                                <div className={style.toolbar}>
                                                    <div
                                                        onClick={async (): Promise<void> =>
                                                            await deleteMaterialFromCollection(
                                                                item.material,
                                                                index,
                                                                false,
                                                                async () => await closeDialog(["dialogMaterialOrder"]),
                                                            )
                                                        }
                                                        data-test-id={`delete.icon.${item.material.id}`}
                                                    >
                                                        <Icon icon={mdiDeleteOutline} color="secondary" size="large" />
                                                    </div>
                                                    <NumberInput
                                                        key="input"
                                                        min={1}
                                                        max={item.material.maximum_amount_orderable}
                                                        value={item.amount}
                                                        onChange={(amount: number): void =>
                                                            onChangeMaterialAmount(item, amount, index)
                                                        }
                                                        increaseText={t("Increase", { ns: "common" })}
                                                        decreaseText={t("Decrease", { ns: "common" })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </li>
                                ))}
                                <li ref={lastListElement} id="last" style={{ height: "1px" }}></li>
                            </ul>
                            <div className={style.scrollIndicator} ref={scrollIndicator} data-test-id="indicator"></div>
                        </div>
                        <hr />
                        <span className={style.actions}>
                            <Button
                                startIcon={mdiArrowLeft}
                                variant="naked"
                                justifiedBetween
                                color="primaryComplex"
                                onClick={async () => {
                                    await closeDialog(["dialogMaterialOrder"], true);
                                }}
                                data-test-id={`button.back.list`}
                            >
                                {t("Back", { ns: "common" })}
                            </Button>
                            <Button
                                color="secondary"
                                onClick={async (): Promise<void> => {
                                    await openDialog("dialogMaterialForm");
                                }}
                                data-test-id={`button.to.order.page`}
                            >
                                {t("Proceed to order", { ns: "materials" })}
                            </Button>
                        </span>
                    </div>
                </div>
            </dialog>
            <dialog id="dialogMaterialForm" className={style.dialog} data-test-id="dialogMaterialForm" ref={formRef}>
                {errors.length > 0 &&
                    errors?.map((error) => (
                        <ErrorMessage
                            key={error.property}
                            message={t(`materials:${Object.values(error.constraints)?.[0] as string}`)}
                            code={error.property}
                            closeAfterSec={5}
                        />
                    ))}
                <div className={classNames(style.overlayContent, style.mobile)}>
                    <div className={style.flexContainer}>
                        <Logo logo={logo} />
                        <div
                            onClick={async () => {
                                await closeDialog(["dialogMaterialForm", "dialogMaterialOrder"], true);
                            }}
                        >
                            <span className={style.closeText} data-test-id="closeTextForm">
                                {t("common:Close")}
                            </span>
                            <Icon icon={mdiClose} color="primary" size="large" verticalAlignMiddle={true} />
                        </div>
                    </div>
                    <div className={style.inner}>
                        <span className={style.intro}>
                            <Icon icon={mdiAccountCircleOutline} size="medium" verticalAlignMiddle />
                            <Typography variant="h6">{t("materials:Your contact details")}</Typography>
                        </span>
                        <hr />
                        <FormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <OrderForm legalDocuments={legalDocuments} orderFormConfig={orderFormConfig} />
                                <hr />
                                <span className={style.actions}>
                                    <Button
                                        startIcon={mdiArrowLeft}
                                        variant="naked"
                                        justifiedBetween
                                        color="primaryComplex"
                                        type="button"
                                        onClick={async () => {
                                            await closeDialog(["dialogMaterialForm"]);
                                        }}
                                        data-test-id={`button.backToOrder`}
                                    >
                                        {t("common:Order overview")}
                                    </Button>
                                    <LoadingButton
                                        color="secondary"
                                        type="submit"
                                        disabled={isLoading}
                                        loading={isLoading}
                                    >
                                        {t("materials:Finalize order")}
                                    </LoadingButton>
                                </span>
                            </form>
                        </FormProvider>
                    </div>
                </div>
                <ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY} ref={recaptchaRef} size="invisible" />
            </dialog>
            <dialog
                id="dialogMaterialThankYou"
                className={style.dialog}
                data-test-id="dialogMaterialThankYou"
                ref={messageRef}
            >
                <div className={style.overlayContent}>
                    <div className={style.flexContainer}>
                        <Logo logo={logo} />
                        <div
                            onClick={async () => {
                                await closeDialog(
                                    ["dialogMaterialThankYou", "dialogMaterialForm", "dialogMaterialOrder"],
                                    true,
                                );
                            }}
                        >
                            <span className={style.closeText} data-test-id="closeTextThankYou">
                                {t("common:Close")}
                            </span>
                            <Icon icon={mdiClose} color="primary" size="large" verticalAlignMiddle={true} />
                        </div>
                    </div>
                    <div className={style.inner}>
                        <span className={style.intro}>
                            <Icon icon={mdiCheckCircleOutline} size="medium" verticalAlignMiddle />
                            <Typography variant="h6">{t("materials:Order completed!")}</Typography>
                        </span>
                        <hr />

                        <Typography variant="h2">{t("materials:Thank you")}</Typography>
                        <Typography paragraph>
                            {t("materials:Thank you for choosing Janssen Medical Cloud for ordering your materials.")}
                        </Typography>

                        <hr />
                        <span className={style.actionsThankYou}>
                            <Button
                                onClick={async () => {
                                    await closeDialog(
                                        ["dialogMaterialThankYou", "dialogMaterialForm", "dialogMaterialOrder"],
                                        true,
                                    );
                                }}
                                data-test-id={`button.backToJMC`}
                                color="secondary"
                            >
                                {t("materials:Back to JMC")}
                            </Button>
                        </span>
                    </div>
                </div>
            </dialog>
        </>
    );
};
