import { NotificationImpression } from "@jmc/core/src/components/NotificationImpression/NotificationImpression";
import { SnackBar } from "@jmc/solid-design-system/src/components/atoms/SnackBar/SnackBar";
import { mdiAlertCircleOutline } from "@mdi/js";
import React, { useEffect, useRef, useState } from "react";

import style from "./style.module.scss";

interface PropTypes {
    message: string | JSX.Element;
    code?: string;
    closeAfterSec?: number;
    onClose?: () => void;
}

export const ErrorMessage: React.FunctionComponent<PropTypes> = ({
    message,
    code = "",
    closeAfterSec = 0,
    onClose,
}: PropTypes): JSX.Element => {
    const [open, setOpen] = useState(true);
    const [action, setAction] = useState(null);

    const timer = useRef(null);

    useEffect(() => {
        if (closeAfterSec > 0) {
            timer.current = setTimeout(() => {
                setOpen(false);
                setAction("disappeared over time");
                onClose && onClose();
            }, closeAfterSec * 1000);
        }
    }, []);

    useEffect(() => {
        if (action) {
            clearTimeout(timer.current);
        }
    }, [action]);

    return (
        <NotificationImpression
            type="error-message"
            trigger="user-action"
            name={code}
            action={action}
            checkVisibility={false}
        >
            {open && (
                <div className={style.error}>
                    <SnackBar
                        onClose={(): void => {
                            setOpen(false);
                            setAction("user closed");
                            onClose && onClose();
                        }}
                        color="error"
                    >
                        <SnackBar.Icon icon={mdiAlertCircleOutline} data-test-id="SnackBar.Icon" />
                        <SnackBar.Text text={message} data-test-id="SnackBar.Text" />
                    </SnackBar>
                </div>
            )}
        </NotificationImpression>
    );
};

export default ErrorMessage;
